import React from "react"
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const ContactPage = ({
    data: {
        site
    },
}) => {
    return (
        <Layout>
            <Helmet>
                <title>Contact Me — {site.siteMetadata.title}</title>
                <meta name="description" content={"Contact page of " + site.siteMetadata.description} />
            </Helmet>
            <div className="flex">
                <div className="md:max-w-1xl sm:mx-auto">
                    <div className="flex flex-col justify-center px-12 bg-white shadow-lg rounded-2xl p-10 items-center">
                        <h1 className="text-3xl text-black font-bold py-2">Contact Me</h1>
                        <div className="max-w-md mx-auto">
                            <div className="flex flex-col py-2 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <p>For business enquiries, email me at<a className="text-blue-500" href="mailto:imdad@imdad.codes">imdad@imdad.codes</a></p>
                            </div>
                            <div className="sender-form-field" data-sender-form-id="a88faa8dc060c5d5PFU"></div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`